import { usePathname } from 'next/navigation';
import {
  Dispatch,
  FC,
  JSX,
  Ref,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { useSpecialsContext } from '@/context';
import { SORT_BY_FIELD_OPTION } from '@/constants';
import { omit, startCase } from 'lodash';
import { getCSSpropety, getPreCartTreezDiscounts } from '@/utils';
import { RefinementList } from './RefinementList';
import { NumericMenu } from './NumericMenu';
import { FiltersModal } from './FiltersModal';
import {
  NewAccordion as Accordion,
  Button,
  Container,
  Icon,
  IconSvg,
  Select,
  useProductsContext,
} from '@/components';
import styles from './FiltersTreez.module.scss';
import classNames from 'classnames';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import { usePopper } from 'react-popper';

export const filterTitleMapping = {
  productTypeName: 'Category',
  brand: 'Brands',
  subType: 'SubType',
  classification: 'Type',
  generals: 'Generals',
  effect: 'Effects',
  flavor: 'Flavors',
  totalThcPercent: 'THC',
  price: 'Price',
  deals: 'Specials',
  ...(process?.env?.NEXT_PUBLIC_TREEZ_FILTERS_TITLE_MAPPING
    ? JSON.parse(process?.env?.NEXT_PUBLIC_TREEZ_FILTERS_TITLE_MAPPING)
    : {}),
};

let defaultFilterOrder = [
  'deals',
  'category',
  'classification',
  'brand',
  'price',
  'thc',
  'effect',
  'flavor',
  'subType',
  'general',
];

if (process.env.NEXT_PUBLIC_TREEZ_FILTER_SORT) {
  defaultFilterOrder = process.env.NEXT_PUBLIC_TREEZ_FILTER_SORT.split(',');
}

const FiltersContainer = ({ children }) => {
  const [top, setTop] = useState<number | null>(null);
  const filtersTop = getCSSpropety('top', top);

  useEffect(() => {
    const handleScroll = () => {
      const header = document.getElementsByTagName('header')[0];
      if (!header) return;

      const headerBottom = header.getBoundingClientRect().bottom;
      setTop(headerBottom);
    };

    handleScroll();

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Container className={styles.section} style={filtersTop}>
      {children}
    </Container>
  );
};

interface IFiltersList {
  className: string;
  showDealsFilter?: boolean;
  priceFilterActive: boolean;
  thcFilterActive: boolean;
  setPriceFilterActive: Dispatch<SetStateAction<boolean>>;
  setThcFilterActive: Dispatch<SetStateAction<boolean>>;
}

const FiltersList: FC<IFiltersList> = ({
  className,
  showDealsFilter,
  priceFilterActive,
  thcFilterActive,
  setPriceFilterActive,
  setThcFilterActive,
}) => {
  const filterComponents = {
    brand: BrandFilter,
    category: CategoryFilter,
    classification: ClassificationFilter,
    effect: EffectsFilter,
    flavor: FlavorsFilter,
    general: GeneralsFilter,
    price: PriceFilter,
    deals: DealsFilter,
    subType: SubtypeFilter,
    thc: THCFilter,
  };

  if (!showDealsFilter) {
    defaultFilterOrder = defaultFilterOrder.filter(f => f !== 'deals');
  }

  return (
    <>
      <ActiveFiltersSection
        setPriceFilterActive={setPriceFilterActive}
        setThcFilterActive={setThcFilterActive}
      />
      <div className={(styles.filters_list__container, className)}>
        <div className={classNames(styles.filters_list)}>
          {defaultFilterOrder.map(filter => {
            const Filter = filterComponents?.[filter];

            if (!Filter) {
              return null;
            }

            if (filter === 'price') {
              return (
                <Filter
                  key={filter}
                  priceFilterActive={priceFilterActive}
                  setPriceFilterActive={setPriceFilterActive}
                />
              );
            }

            if (filter === 'thc') {
              return (
                <Filter
                  key={filter}
                  setThcFilterActive={setThcFilterActive}
                  thcFilterActive={thcFilterActive}
                />
              );
            }

            return <Filter key={filter} />;
          })}
        </div>
      </div>
    </>
  );
};

const extraFilters: IOutsideFilter[] = [
  { name: 'deals', icon: 'gift', type: 'button', id: 'active' },
  { name: 'category', icon: 'leaf' },
  { name: 'classification', icon: 'drop' },
  { name: 'brand' },
];

const activesExtraFilters = defaultFilterOrder
  .map(name => extraFilters.find(filter => filter.name === name))
  .filter(Boolean) as IOutsideFilter[];

const OutsideFilters = () => {
  return (
    <div className={styles.slider_container}>
      {activesExtraFilters?.map(({ name, icon, type, id }) => (
        <OutsideFilter
          icon={icon}
          id={id}
          key={`outside-filter-${name}`}
          name={name}
          type={type}
        />
      ))}
    </div>
  );
};

interface IOutsideFilter {
  name: 'deals' | 'category' | 'classification' | 'brand' | 'subType';
  icon?: string;
  type?: 'button' | 'dropdown';
  id?: string;
}

const OutsideFilter: FC<IOutsideFilter> = ({
  name,
  icon,
  type = 'dropdown',
  id,
}) => {
  const [button, setButton] = useState<HTMLButtonElement | undefined>();
  const [panel, setPanel] = useState(null);
  const pathname = usePathname();
  const { activeFilters, refine } = useProductsContext();

  const isDropdownType = type === 'dropdown';
  let backendFilterName:
    | 'deals'
    | 'category'
    | 'classification'
    | 'brand'
    | 'subType'
    | 'productTypeName' = name;

  const { styles: popperStyles, attributes } = usePopper(button, panel, {
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 10],
        },
      },
      {
        name: 'preventOverflow',
        options: {
          padding: 16,
        },
      },
    ],
    placement: 'bottom-start',
  });

  if (name === 'category') {
    backendFilterName = 'productTypeName';
  }

  const {
    activeFiltersGroupCount: { [backendFilterName]: count },
    clearFiltersGroup,
  } = useProductsContext();

  const evaluateRoute = () =>
    (name === 'brand' && pathname?.includes('brand')) ||
    (name === 'category' && pathname?.includes('collection')) ||
    (name === 'subType' && pathname?.includes('subcategory')) ||
    (name === 'classification' && pathname?.includes('strain')) ||
    (name === 'deals' &&
      (pathname?.includes('deals') || pathname?.includes('special')));

  if (evaluateRoute()) {
    return null;
  }

  const handleButtonClick = () => {
    if (isDropdownType) {
      return;
    }

    !!id && refine(name, id, !activeFilters[name]?.[id]);
  };

  if (!isDropdownType && name === 'deals' && !!id) {
    return (
      <div className={styles.section__filter_group_select}>
        <Button
          className={classNames(styles.section__select_button, styles.item, {
            [styles.section__select_button_active]: !!count,
          })}
          onClick={handleButtonClick}
          size="md"
        >
          {icon && <IconSvg name={icon} />}
          {filterTitleMapping.deals}
          {!!count && (
            <span className={styles.filters_count}>{`${count}`}</span>
          )}
        </Button>
      </div>
    );
  }

  return (
    <Popover className={styles.section__filter_group_select}>
      {({ open }) => (
        <>
          <PopoverButton
            aria-label="Show info"
            className={classNames(styles.section__select_button, styles.item, {
              [styles.section__select_button_open]: open,
              [styles.section__select_button_active]: !!count,
            })}
            ref={setButton as Ref<HTMLButtonElement>}
          >
            <div className={styles.section__select_button_label}>
              {icon && <IconSvg name={icon} />}
              {filterTitleMapping[backendFilterName]}
              {!!count && (
                <span className={styles.filters_count}>{`${count}`}</span>
              )}
            </div>
            {isDropdownType && <IconSvg name="chevron-right" />}
          </PopoverButton>
          <PopoverPanel
            className={styles.section__select_panel}
            ref={setPanel as Ref<HTMLDivElement>}
            style={popperStyles.popper}
            {...attributes.popper}
          >
            <SingleFilter filter={name} />
            <div className={styles.button_row}>
              <Button
                className={styles.panel_button}
                color="secondary"
                onClick={() => clearFiltersGroup(backendFilterName)}
              >
                <IconSvg name="bin" />
                <span>Clear</span>
              </Button>
            </div>
          </PopoverPanel>
        </>
      )}
    </Popover>
  );
};

interface ISingleFilter {
  className?: string;
  priceFilterActive?: boolean;
  thcFilterActive?: boolean;
  setPriceFilterActive?: boolean;
  setThcFilterActive?: boolean;
  filter:
    | 'brand'
    | 'category'
    | 'classification'
    | 'deals'
    | 'price'
    | 'productTypeName'
    | 'subType'
    | 'thc';
}

const SingleFilter: FC<ISingleFilter> = ({
  className,
  priceFilterActive,
  thcFilterActive,
  setPriceFilterActive,
  setThcFilterActive,
  filter,
}) => {
  const filterComponents = {
    brand: BrandFilter,
    category: CategoryFilter,
    classification: ClassificationFilter,
    effect: EffectsFilter,
    flavor: FlavorsFilter,
    general: GeneralsFilter,
    price: PriceFilter,
    subType: SubtypeFilter,
    thc: THCFilter,
  };

  const Filter = filterComponents[filter];

  if (!Filter) {
    return null;
  }

  return (
    <>
      <div className={(styles.filters_list__container, className)}>
        <div className={classNames(styles.filters_list)}>
          {filter === 'price' && (
            <Filter
              key={filter}
              priceFilterActive={priceFilterActive}
              setPriceFilterActive={setPriceFilterActive}
            />
          )}
          {filter === 'thc' && (
            <Filter
              key={filter}
              setThcFilterActive={setThcFilterActive}
              thcFilterActive={thcFilterActive}
            />
          )}
          {filter !== 'thc' && filter !== 'price' && (
            <Filter hasScrollBar key={filter} />
          )}
        </div>
      </div>
    </>
  );
};

const FilterButton = ({ onClick }) => {
  const { activeFiltersCount } = useProductsContext();

  return (
    <div
      className={classNames(styles.filter_menu__button, {
        [styles.filter_menu__button_active]: !!activeFiltersCount,
      })}
      onClick={onClick}
      role="button"
    >
      <IconSvg name="simple-filter" />
      <span className={styles.title}>
        FILTER
        {!!activeFiltersCount && (
          <span
            className={styles.filters_count}
          >{`${activeFiltersCount}`}</span>
        )}
      </span>
    </div>
  );
};

interface IActiveFiltersSection {
  setPriceFilterActive: (_: boolean) => void;
  setThcFilterActive: (_: boolean) => void;
}

const ActiveFiltersSection: FC<IActiveFiltersSection> = ({
  setPriceFilterActive,
  setThcFilterActive,
}) => {
  const {
    activeFilters: filters,
    activeFiltersCount,
    refine,
  } = useProductsContext();

  if (!activeFiltersCount) {
    return null;
  }

  const selectFilters = omit(filters, ['special_id', 'productId']);
  const filtersArray = Object.entries(selectFilters);

  return (
    <div className={styles.active_filters}>
      <div className={styles.active_filters_container}>
        {filtersArray
          .filter(
            ([_filterTitle, activeFilters]) =>
              activeFilters && Object.values(activeFilters).length > 0,
          )
          .map(([title, activeFilters]) => {
            const filterTitle = filterTitleMapping[title];

            return (
              <div className={styles.filter_container} key={filterTitle}>
                <p className={styles.filter_title}>{filterTitle}</p>
                <div className={styles.filter_list}>
                  {activeFilters &&
                    Object.entries(activeFilters)
                      .filter(([_filterName, isActive]) => isActive)
                      .map(([name, _isActive]) => {
                        let filterName = name;

                        if (title === 'price') {
                          filterName = `Less than $${Number(filterName) + 1}`;
                        }

                        if (title === 'totalThcPercent') {
                          filterName = `More than ${Number(filterName) - 1}%`;
                        }

                        return (
                          <Button
                            className={styles.filter_pill}
                            key={filterName}
                            onClick={() => {
                              if (title === 'price') {
                                setPriceFilterActive(false);
                                return;
                              }
                              if (title === 'totalThcPercent') {
                                setThcFilterActive(false);
                                return;
                              }
                              return refine(
                                title,
                                name,
                                !filters[title]?.[name],
                              );
                            }}
                          >
                            {filterName}
                            <span className={styles.pill_icon}>
                              <Icon name={'close'} />
                            </span>
                          </Button>
                        );
                      })}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

const BrandFilter = ({ ...props }) => {
  const pathname = usePathname();

  if (pathname?.includes('brand')) {
    return null;
  }

  return (
    <RefinementSection
      attribute="brand"
      className={styles.refinement_list}
      configValue="brands"
      hasSearchBar
      searchBarPlaceholder="Select brands"
      {...props}
    />
  );
};

const CategoryFilter = ({ ...props }) => {
  const pathname = usePathname();

  if (pathname?.includes('collection')) {
    return null;
  }

  return (
    <RefinementSection
      attribute="productTypeName"
      className={styles.refinement_list}
      {...props}
    />
  );
};

const DealsFilter = () => {
  const { specials } = useSpecialsContext();

  const preCartTreezDiscounts = getPreCartTreezDiscounts(specials);

  if (!preCartTreezDiscounts?.length) {
    return null;
  }

  return (
    <RefinementSection
      attribute="deals"
      className={styles.refinement_list}
      configValue="deals"
    />
  );
};

const SubtypeFilter = ({ ...props }) => {
  const pathname = usePathname();

  if (pathname?.includes('subcategory')) {
    return null;
  }

  return (
    <RefinementSection
      attribute="subType"
      className={styles.refinement_list}
      configValue="subTypes"
      hasSearchBar
      searchBarPlaceholder="Select subtypes"
      {...props}
    />
  );
};

const ClassificationFilter = ({ ...props }) => {
  const pathname = usePathname();

  if (pathname?.includes('strain')) {
    return null;
  }

  return (
    <RefinementSection
      attribute="classification"
      className={styles.refinement_list}
      configValue="classifications"
      {...props}
    />
  );
};

const GeneralsFilter = ({ ...props }) => (
  <RefinementSection
    attribute="generals"
    className={styles.refinement_list}
    configValue="general"
    hasSearchBar
    searchBarPlaceholder="Select generals"
    {...props}
  />
);

const EffectsFilter = ({ ...props }) => (
  <RefinementSection
    attribute="effect"
    className={styles.refinement_list}
    hasSearchBar
    searchBarPlaceholder="Select effects"
    {...props}
  />
);

const FlavorsFilter = ({ ...props }) => (
  <RefinementSection
    attribute="flavor"
    className={styles.refinement_list}
    hasSearchBar
    searchBarPlaceholder="Select a flavor"
    {...props}
  />
);

const THCFilter = ({ thcFilterActive, setThcFilterActive }) => {
  // Treez Docs: The minimum THC % by which to filter.

  const items = [
    { label: 'All' },
    { label: 'More than 5%', value: '6' },
    { label: 'More than 10%', value: '11' },
    { label: 'More than 25%', value: '26' },
  ];

  return (
    <Accordion
      classNameHeader={styles.filters__header_section}
      isExpanded
      label={filterTitleMapping ? filterTitleMapping.totalThcPercent : 'THC'}
    >
      <NumericMenu
        attribute="totalThcPercent"
        isActive={thcFilterActive}
        items={items}
        setActive={setThcFilterActive}
      />
    </Accordion>
  );
};

const PriceFilter = ({ priceFilterActive, setPriceFilterActive }) => {
  // Treez Docs: The maximum retail price by which to filter.

  const items = [
    { label: 'All' },
    { label: 'Less than $20', value: '19' },
    { label: 'Less than $40', value: '39' },
    { label: 'Less than $60', value: '59' },
  ];

  return (
    <Accordion
      classNameHeader={styles.filters__header_section}
      isExpanded
      label={filterTitleMapping ? filterTitleMapping.price : 'Price'}
    >
      <NumericMenu
        attribute="price"
        isActive={priceFilterActive}
        items={items}
        setActive={setPriceFilterActive}
      />
    </Accordion>
  );
};

const SortByMenu = () => {
  const { activeSort, applySort } = useProductsContext();

  return (
    <div className={styles.sort_by_button}>
      <Select
        className={styles.sort_by_select}
        defaultSelectedLabelClassName={styles.sort_by_default_selected_label}
        menuClassName={styles.sort_by_select_menu}
        menuOptionClassName={styles.sort_by_menu_option}
        onChange={e => applySort(e)}
        options={SORT_BY_FIELD_OPTION}
        placeholderLabel="Sort By"
        placeholderLabelClassName={styles.sort_by_placeholder_label}
        selectLabelClassName={styles.sort_by_select_label}
        value={activeSort}
      />
    </div>
  );
};

const WithFilterAttributeCountLabel = ({
  children,
  attribute,
}: {
  attribute: string;
  // eslint-disable-next-line no-undef
  children: (_: string) => JSX.Element;
}) => {
  const {
    activeFiltersGroupCount: { [attribute]: count },
  } = useProductsContext();
  const countLabel = count ? `(${count})` : '';

  return children(countLabel);
};

const RefinementSection: FC<{
  attribute: string; // this is the attribute use for filter on Treez search product API
  configValue?: string; // the name of the attribute on the config object return by the Treez Config API
  className: string;
  hasSearchBar?: boolean;
  searchBarPlaceholder?: string;
}> = ({ attribute, className, ...props }) => (
  <WithFilterAttributeCountLabel attribute={attribute}>
    {countLabel => (
      <Accordion
        classNameHeader={styles.filters__header_section}
        isExpanded
        label={
          <>
            <span>
              {filterTitleMapping
                ? filterTitleMapping[attribute]
                : startCase(attribute)}
            </span>
            <span>{countLabel}</span>
          </>
        }
      >
        <RefinementList
          attribute={attribute}
          className={className}
          {...props}
        />
      </Accordion>
    )}
  </WithFilterAttributeCountLabel>
);

export {
  FiltersContainer,
  FiltersList,
  OutsideFilters,
  FilterButton,
  SortByMenu,
  FiltersModal,
};
